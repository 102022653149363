import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import RouteGuard from "./components/RouteGuard";

//pages
import Login, { Logout } from "./pages/Login";
import Home from "./pages/Home";
import Branches from "./pages/Branches";
import Areas from "./pages/Areas";
import Regions from "./pages/Regions";
import Divisions from "./pages/Divisions";
import Positions from "./pages/Positions";
import Employees from "./pages/Employees";
import Leaves from "./pages/Leaves";
import Users from "./pages/Users";
import Departments from './pages/Departments';

function RoutePages() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="home" exact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />

            <Route element={<RouteGuard />}>
                <Route path="/home" element={<Home />} />

                <Route path="/branches" element={<Branches />} />
                <Route path="/branches/branch" element={<Branches isForm={true} />} />
                <Route path="/branches/view/:id" element={<Branches isForm={true} />} />

                <Route path="/areas" element={<Areas />} />
                <Route path="/areas/area" element={<Areas isForm={true} />} />
                <Route path="/areas/view/:id" element={<Areas isForm={true} />} />

                <Route path="/regions" element={<Regions />} />
                <Route path="/regions/region" element={<Regions isForm={true} />} />
                <Route path="/regions/view/:id" element={<Regions isForm={true} />} />

                <Route path="/divisions" element={<Divisions />} />
                <Route path="/divisions/division" element={<Divisions isForm={true} />} />
                <Route path="/divisions/view/:id" element={<Divisions isForm={true} />} />

                <Route path="/positions" element={<Positions />} />
                <Route path="/positions/position" element={<Positions isForm={true} />} />
                <Route path="/positions/view/:id" element={<Positions isForm={true} />} />

                <Route path="/employees" element={<Employees />} />
                <Route path="/employees/employee" element={<Employees isForm={true} />} />
                <Route path="/employees/view/:id" element={<Employees isForm={true} />} />

                <Route path="/leaves" element={<Leaves />} />
                <Route path="/leaves/leave" element={<Leaves isForm={true} />} />
                <Route path="/leaves/view/:id" element={<Leaves isForm={true} />} />

                <Route path="/users" element={<Users />} />
                <Route path="/users/user" element={<Users isForm={true} />} />
                <Route path="/users/view/:id" element={<Users isForm={true} />} />

                <Route path="/departments" element={<Departments />} />
                <Route path="/departments/department" element={<Departments isForm={true} />} />
                <Route path="/departments/view/:id" element={<Departments isForm={true} />} />
            </Route>
        </Routes>
    );
}

export default RoutePages