import React, { useLayoutEffect, useState } from "react";

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { mergeJSON } from '../../../helpers/json-utils';

const emptyData = {
    fatherId: "",
    fatherName: "",
    fatherOccupation: "",
    fatherEmployer: "",
    fatherAddress: "",
    fatherAddressMunicipality: "",
    fatherAddressProvince: "",
    fatherContactNumber: "",
    motherId: '',
    motherName: "",
    motherOccupation: "",
    motherEmployer: "",
    motherAddress: "",
    motherAddressMunicipality: "",
    motherAddressProvince: "",
    motherContactNumber: "",
    guardianId: '',
    guardianName: "",
    guardianOccupation: "",
    guardianEmployer: "",
    guardianAddress: "",
    guardianAddressMunicipality: "",
    guardianAddressProvince: "",
    guardianContactNumber: ""
}

export default function ParentsGuardianData({ data, updateEmployee, readOnly }) {
    const [parentsGuardianData, setParentsGuardianData] = useState(emptyData);

    useLayoutEffect(() => {
        setParentsGuardianData(mergeJSON(emptyData, data));
    }, [data]);

    const updateField = (fieldName, value) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string') ? value.toUpperCase(): value;
        setParentsGuardianData(b => ({
            ...b,
            ...dirty
        }));
    };

    return (
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <Typography variant="h6" align="left">
                    Father
                </Typography>
            </Grid>
            <Grid item sm={6}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="fatherName"
                    label="Full Name"
                    value={parentsGuardianData.fatherName}
                    onChange={(e) => updateField('fatherName', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="fatherOccupation"
                    label="Occupation"
                    value={parentsGuardianData.fatherOccupation}
                    onChange={(e) => updateField('fatherOccupation', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="fatherEmployer"
                    label="Employer"
                    value={parentsGuardianData.fatherEmployer}
                    onChange={(e) => updateField('fatherEmployer', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={12}>
                <Typography variant="body1" align="left">
                    Father's Address
                </Typography>
            </Grid>
            <Grid item sm={6} style={{ paddingTop: 0 }}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="fatherAddress"
                    label="Address"
                    value={parentsGuardianData.fatherAddress}
                    onChange={(e) => updateField('fatherAddress', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3} style={{ paddingTop: 0 }}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="fatherAddressMunicipality"
                    label="Municipality"
                    value={parentsGuardianData.fatherAddressMunicipality}
                    onChange={(e) => updateField('fatherAddressMunicipality', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3} style={{ paddingTop: 0 }}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="fatherAddressProvince"
                    label="Province"
                    value={parentsGuardianData.fatherAddressProvince}
                    onChange={(e) => updateField('fatherAddressProvince', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={4}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="fatherContactNumber"
                    label="Contact #"
                    value={parentsGuardianData.fatherContactNumber}
                    onChange={(e) => updateField('fatherContactNumber', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={12}>
                <Typography variant="h6" align="left">
                    Mother
                </Typography>
            </Grid>
            <Grid item sm={6}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="motherName"
                    label="Full Name"
                    value={parentsGuardianData.motherName}
                    onChange={(e) => updateField('motherName', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="motherOccupation"
                    label="Occupation"
                    value={parentsGuardianData.motherOccupation}
                    onChange={(e) => updateField('motherOccupation', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="motherEmployer"
                    label="Employer"
                    value={parentsGuardianData.motherEmployer}
                    onChange={(e) => updateField('motherEmployer', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={12}>
                <Typography variant="body1" align="left">
                    Mother's Address
                </Typography>
            </Grid>
            <Grid item sm={6} style={{ paddingTop: 0 }}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="motherAddress"
                    label="Address"
                    value={parentsGuardianData.motherAddress}
                    onChange={(e) => updateField('motherAddress', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3} style={{ paddingTop: 0 }}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="motherAddressMunicipality"
                    label="Municipality"
                    value={parentsGuardianData.motherAddressMunicipality}
                    onChange={(e) => updateField('motherAddressMunicipality', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3} style={{ paddingTop: 0 }}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="motherAddressProvince"
                    label="Province"
                    value={parentsGuardianData.motherAddressProvince}
                    onChange={(e) => updateField('motherAddressProvince', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="motherContactNumber"
                    label="Contact #"
                    value={parentsGuardianData.motherContactNumber}
                    onChange={(e) => updateField('motherContactNumber', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={12}>
                <Typography variant="h6" align="left">
                    Guardian
                </Typography>
            </Grid>
            <Grid item sm={6}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="guardianName"
                    label="Full Name"
                    value={parentsGuardianData.guardianName}
                    onChange={(e) => updateField('guardianName', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="guardianOccupation"
                    label="Occupation"
                    value={parentsGuardianData.guardianOccupation}
                    onChange={(e) => updateField('guardianOccupation', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="guardianEmployer"
                    label="Employer"
                    value={parentsGuardianData.guardianEmployer}
                    onChange={(e) => updateField('guardianEmployer', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={12}>
                <Typography variant="body1" align="left">
                    Guardian's Address
                </Typography>
            </Grid>
            <Grid item sm={6} style={{ paddingTop: 0 }}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="guardianAddress"
                    label="Address"
                    value={parentsGuardianData.guardianAddress}
                    onChange={(e) => updateField('guardianAddress', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3} style={{ paddingTop: 0 }}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="guardianAddressMunicipality"
                    label="Municipality"
                    value={parentsGuardianData.guardianAddressMunicipality}
                    onChange={(e) => updateField('guardianAddressMunicipality', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3} style={{ paddingTop: 0 }}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="guardianAddressProvince"
                    label="Province"
                    value={parentsGuardianData.guardianAddressProvince}
                    onChange={(e) => updateField('guardianAddressProvince', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={3}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="guardianContactNumber"
                    label="Contact #"
                    value={parentsGuardianData.guardianContactNumber}
                    onChange={(e) => updateField('guardianContactNumber', e.target.value)}
                    onBlur={(e) => updateEmployee('parentsGuardianData', parentsGuardianData)}
                    fullWidth
                    variant="standard" />
            </Grid>
        </Grid>
    );
}