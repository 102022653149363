import React from "react";
import moment from 'moment';

import Typography from '@mui/material/Typography';

import Dashboard from "../components/dashboard";

export default function Home() {
    const user = JSON.parse(localStorage.getItem("user"));
    const hour = moment().hour();

    return (
        <Dashboard title="Dashboard">
            <Typography variant="h2">
                Good {hour < 12 ? 'morning' : (hour < 18 ? 'afternoon' : 'evening')}, {user.firstName} {user.lastName}.
            </Typography>
        </Dashboard>
    );
}
