import React, { useLayoutEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import AppBar from '@mui/material/AppBar';

import {
    saveEmployee
} from '../../../services/employees';
import PersonalData from './personal-data';
import SpouseChildrenData from './spouse-children-data';
import ParentsGuardianData from './parents-guardian-data';
import EducationalData from './educational-data';
import EmploymentHistoryData from './employment-history-data';
import TrainingAttendedData from './training-attended-data';
import CharacterReferenceData from './character-reference-data';
import BeneficiariesReferenceData from './beneficiaries-reference-data';
import RemittanceData from './remittance-data';

const emptyData = {
    id: "",
    personalData: {},
    spouseChildrenData: {},
    parentsGuardianData: {},
    educationsData: [],
    employmentsData: [],
    trainingsData: [],
    characterReferencesData: [],
    beneficiariesData: [],
    remittanceData: {},
    employmentInfoData: {}
}

export default function EmployeeDetails({data, popUpMessage, setLoading, onChange}) {
    const [originalData, setOriginalData] = useState({});
    const [employee, setEmployee] = useState(emptyData);
    const [readOnly, setReadOnly] = useState(false);

    useLayoutEffect(() => {
        if (!data) return;

        setEmployee({
            ...emptyData,
            ...data,
        });
        setOriginalData(data);
        setReadOnly(!!data.id);
    }, [data]);

    const updateEmployee = (fieldName, value) => {
        let dirty = {};
        dirty[fieldName] = value;
        setEmployee(b => ({
            ...b,
            ...dirty
        }));
    }

    const save = async () => {
        setLoading(true);
        await saveEmployee({
            employee: employee
        }).then((res) => {
            setLoading(false);
            onChange(res.data);
            popUpMessage(true, 'Successfully saved employee details.');
            setReadOnly(true);
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    }

    const cancel = async () => {
        setEmployee(originalData);
        setReadOnly(true);
    }

    return (
        <Box component="form">
            <PersonalData data={employee.personalData} updateEmployee={updateEmployee} readOnly={readOnly} />
            <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                Spouse and Children Information
            </Typography>
            <SpouseChildrenData data={employee.spouseChildrenData} updateEmployee={updateEmployee} readOnly={readOnly} />
            <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                Parents/Guardian Information
            </Typography>
            <ParentsGuardianData data={employee.parentsGuardianData} updateEmployee={updateEmployee} readOnly={readOnly} />
            <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                Educational Background
            </Typography>
            <EducationalData data={employee.educationsData} updateEmployee={updateEmployee} readOnly={readOnly} />
            <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                Employment Record
            </Typography>
            <EmploymentHistoryData data={employee.employmentsData} updateEmployee={updateEmployee} readOnly={readOnly} />
            <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                Training-workshop Attended
            </Typography>
            <TrainingAttendedData data={employee.trainingsData} updateEmployee={updateEmployee} readOnly={readOnly} />
            <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                Character Reference
            </Typography>
            <CharacterReferenceData data={employee.characterReferencesData} updateEmployee={updateEmployee} readOnly={readOnly} />
            <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                Beneficiaries Reference
            </Typography>
            <BeneficiariesReferenceData data={employee.beneficiariesData} updateEmployee={updateEmployee} readOnly={readOnly} />
            <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                Remittances
            </Typography>
            <RemittanceData data={employee.remittanceData} updateEmployee={updateEmployee} readOnly={readOnly} />
            <AppBar position="fixed" color="inherit" sx={{ p: 1, top: 'auto', bottom: 0 }}>
                <Box sx={{ pl: 8, display: 'flex', justifyContent: 'center', minHeight: 40 }}>
                    {!readOnly ?
                        <React.Fragment>
                            <Button onClick={save} variant="contained" startIcon={<SaveIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                Save
                            </Button>
                            {!!employee.id &&
                                <Button onClick={cancel} variant="contained" startIcon={<CancelIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                    Cancel
                                </Button>
                            }
                        </React.Fragment>
                    :
                        <Button onClick={() => setReadOnly(false)} variant="contained" startIcon={<EditIcon />} sx={{ ml: 1, minWidth: 140 }}>
                            Edit
                        </Button>
                    }
                </Box>
            </AppBar>
        </Box>
    );
}