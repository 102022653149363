import './App.css';

import RoutePages from './routes'

function App() {

  //check jwt token
  /*const token = localStorage.getItem("token");
  if (token) {
      setAuthToken(token);
  }*/

  return (
    <div className="App">
      <RoutePages />
    </div>
  );
}

export default App;