import React, { useState, useLayoutEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import {
    getAllBranches,
} from '../../services/branches';
import {
    getAllAreas,
} from '../../services/areas';
import {
    getAllRegions,
} from '../../services/regions';
import {
    getAllDivisions,
} from '../../services/divisions';
import {
    getAllPositions,
} from '../../services/positions';
import {
    getEmployee,
    getEmployment,
    getLeaves,
} from '../../services/employees';
import {
    savePhoto
} from '../../services/employeephotos';
import {
    getAttachments,
} from '../../services/attachments';
import Title from '../../components/Title';
import EmployeeDetails from "./EmployeeDetails";
import ContractDetails from "./contract-details";
import LeaveDetails from "./leave-details";
import EmployeeAttachment from "./attachments";
import { getAllDepartments } from '../../services/depratments';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Employee() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    const [branches, setBranches] = useState([]);
    const [areas, setAreas] = useState([]);
    const [regions, setRegions] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [positions, setPositions] = useState([]);
    const [employee, setEmployee] = useState({});
    const [employment, setEmployment] = useState({});
    const [leaves, setLeaves] = useState({});
    const [attachments, setAttachments] = useState([]);
    const [departments, setDepartments] = useState([]);

    const [openMessage, setOpenMessage] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');

    useLayoutEffect(() => {
        if (!id) {
            return;
        }

        setLoading(true);
        Promise.all([
            getEmployee({ id: id }),
            getEmployment({ id: id }),
            getLeaves({ id: id }),
            getAttachments({ empId: id })
        ]).then(([employeeRes, employmentRes, leavesRes, attachmentsRes]) => {
            setEmployee(employeeRes.data);
            setEmployment(employmentRes.data);
            setLeaves(leavesRes.data);
            setAttachments(attachmentsRes.data);
            setLoading(false);
        })
    }, [id]);

    useLayoutEffect(() => {
        getAllBranches({ sort: 'code' }).then((res) => setBranches(res.data));
        getAllAreas({ sort: 'name' }).then((res) => setAreas(res.data));
        getAllRegions({ sort: 'name' }).then((res) => setRegions(res.data));
        getAllDivisions({ sort: 'name' }).then((res) => setDivisions(res.data));
        getAllPositions({ sort: 'name' }).then((res) => setPositions(res.data));
        getAllDepartments({ sort: 'name' }).then(res => setDepartments(res.data));
    }, []);

    const popUpMessage = (isOk, message) => {
        setSuccess(isOk);
        setMessage(message);
        setOpenMessage(true);
    }

    const handleUploadClick = (event) => {
        const file = event.target.files[0];
        const filesize = ((file.size / 1024) / 1024).toFixed(4);
        if (filesize > 5) {
            popUpMessage(false, "Photo must be less than 5MB");
            event.target.value = "";
            return;
        }

        let data = new FormData();
        data.append('photo', file, file.name);
        data.append('id', employee.id);

        setLoading(true);
        savePhoto(data).then((res) => {
            setLoading(false);
            popUpMessage(true, 'Successfully uploaded employee photo.');
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    };

    const handleUpdateEmployeeDetails = (e) => {
        setEmployee(e);
        if (!employment || !employment.id) {
            const dirty = {
                id: e.id,
            };
            setEmployment(b => ({
                ...b,
                ...dirty
            }));
        }
    };

    return (
        <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                    <Stack sx={{ ml: -5, mt: -5 }} direction="row" alignItems="center" spacing={1}>
                        <IconButton size="large" component={Link} onClick={() => navigate(-1)}>
                          <ArrowBackIcon fontSize="inherit" />
                        </IconButton>
                    </Stack>
                    <Title>Employee Details</Title>
                </Grid>
                <Grid item xs={1} container justifyContent="flex-end" alignItems="center">
                    <Box sx={{ mr: 2 }}>
                        <Typography component="h1" variant="h2" color="red" gutterBottom align="left" fontWeight='fontWeightMedium'>
                            {employment?.idNo}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {employee.id &&
                            <IconButton variant="contained" component="label">
                                {employee.hasPhoto ?
                                    <Avatar src={`${process.env.REACT_APP_API_URL}/api/employee-photo/image/${employee.id}?rnd=${new Date().getTime()}`} sx={{ width: 120, height: 120 }} />
                                    :
                                    <Avatar sx={{ width: 120, height: 120 }}>Upload Photo</Avatar>
                                }
                                <input type="file" hidden onChange={handleUploadClick} />
                            </IconButton>
                        }
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ mt: 5, minHeight: '60vh', marginTop: '0' }}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={(e, val) => setActiveTab(val)} aria-label="basic tabs example"
                        variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                        <Tab label="Personal Details" {...a11yProps(0)} />
                        <Tab label="Contract Details" {...a11yProps(1)} disabled={employee.id ? false : true} />
                        <Tab label="Leave Details" {...a11yProps(2)} disabled={employee.id ? false : true} />
                        <Tab label="Attachments" {...a11yProps(3)} disabled={employee.id ? false : true} />
                    </Tabs>
                </Box>
                <TabPanel value={activeTab} index={0}>
                    <EmployeeDetails data={employee} popUpMessage={popUpMessage} setLoading={setLoading} onChange={(e) => handleUpdateEmployeeDetails(e)} />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <ContractDetails data={employment} branches={branches} areas={areas} regions={regions} divisions={divisions} departments={departments}
                        positions={positions} popUpMessage={popUpMessage} setLoading={setLoading} onChange={(e) => setEmployment(e)} />
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    <LeaveDetails data={leaves} hasLeaveInfo={employee.hasLeaveInfo} gender={employee.personalData?.gender} popUpMessage={popUpMessage} setLoading={setLoading} onChange={(e) => setEmployment(e)} />
                </TabPanel>
                <TabPanel value={activeTab} index={3}>
                    <EmployeeAttachment data={attachments} empId={employee.id} popUpMessage={popUpMessage} setLoading={setLoading} />
                </TabPanel>
            </Box>
            <Dialog open={openMessage}>
                <DialogTitle id="alert-dialog-title">
                    {success ? "Success": "Error"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenMessage(!openMessage)} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Paper>
    );
}