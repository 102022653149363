import api from "./api";

export const getEmployees = ({name = '', page = 0, size = 10, sort}) => {
    const params = new URLSearchParams({
        name: name,
        page: page,
        size: size,
        sort: sort
    });
    return api.get("/api/employee/list?" + params);
};

export const getAllEmployees = ({name = '', sort = 'lastName,firstName,suffix,middleName'}) => {
    const params = new URLSearchParams({
        name: name,
        sort: sort
    });
    return api.get("/api/employee/list-all?" + params);
};

export const getEmployee = ({id}) => {
    const params = new URLSearchParams({
        id: id
    });
    return api.get("/api/employee?" + params);
}

export const saveEmployee = ({employee}) => {
    return api.post("/api/employee/save", employee);
};

export const getEmployment = ({id}) => {
    const params = new URLSearchParams({
        id: id
    });
    return api.get("/api/employment?" + params);
};

export const saveEmployment = ({employment}) => {
    return api.post("/api/employment/save", employment);
};

export const getLeaves = ({id}) => {
    const params = new URLSearchParams({
        id: id
    });
    return api.get("/api/employee-leave?" + params);
};

export const saveLeaves = ({leaves}) => {
    return api.post("/api/employee-leave/save", leaves);
};

export const generateIdNo = () => {
    return api.get("/api/employment/generateIdNo");
};