import api from "./api";

export const getAttachments = ({empId}) => {
    const params = new URLSearchParams({
        empId: empId
    });
    return api.get("/api/employee-attachment/attachments?" + params);
};

export const saveAttachment = (form) => {
    return api.post("/api/employee-attachment/save", form, {
        headers: {
            "Content-type": "multipart/form-data",
        },
    });
};

export const removeAttachment = ({id}) => {
    return api.delete("/api/employee-attachment/delete/" + id);
}