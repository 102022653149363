import React, { useLayoutEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import moment from 'moment';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import AppBar from '@mui/material/AppBar';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Title from '../../components/Title';
import { getLeave, saveLeave } from "../../services/leaves";
import { getAllEmployees } from "../../services/employees";
import { mergeJSON } from '../../helpers/json-utils';

const emptyData = {
    id: '',
    type: '',
    fromDate: '',
    fromDateMoment: null,
    toDate: '',
    toDateMoment: null,
    reason: '',
    employeeInfoData: null,
};

export default function Leave() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [originalData, setOriginalData] = useState(emptyData);
    const [leave, setLeave] = useState(emptyData);
    const [readOnly, setReadOnly] = useState(false);
    const [employees, setEmployees] = useState([]);
    const previousController = useRef();
    const [openMessage, setOpenMessage] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');

    useLayoutEffect(() => {
        if (!id) return;

        setLoading(true);
        getLeave({
            id: id
        }).then((res) => {
            const data = res.data;
            const origLeave = mergeJSON(emptyData, {
                ...data,
                fromDateMoment: data?.fromDate ? moment(data?.fromDate, 'MM/DD/YYYY') : null,
                toDateMoment: data?.toDate ? moment(data?.toDate, 'MM/DD/YYYY') : null,
            });
            setLeave(origLeave);
            setOriginalData(origLeave);
            setReadOnly(true);
            setLoading(false);
        });
    }, [id]);

    const save = () => {
        setLoading(true);
        saveLeave({
            leave: leave
        }).then((res) => {
            setLoading(false);
            setLeave(res.data);
            popUpMessage(true, 'Successfully saved leave details.');
            setReadOnly(true);
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    };

    const updateField = (fieldName, value) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string') ? value.toUpperCase(): value;
        setLeave(b => ({
            ...b,
            ...dirty
        }));
    };

    const popUpMessage = (isOk, message) => {
        setSuccess(isOk);
        setMessage(message);
        setOpenMessage(true);
    }

    const cancel = async () => {
        setLeave(originalData);
        setReadOnly(true);
    }

    const getData = (name) => {
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        previousController.current = controller;
        getAllEmployees({
            name: name
        }).then((res) => {
            setEmployees(res.data);
        });
    };

    const onInputEmployeeChange = (event, name) => {
        if (name && name.length >= 3) {
            getData(name);
        } else {
            setEmployees([]);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
                <Stack sx={{ ml: -5, mt: -5 }} direction="row" alignItems="center" spacing={1}>
                    <IconButton size="large" component={Link} onClick={() => navigate(-1)}>
                      <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                </Stack>
                <Title>Leave Details</Title>
                <Box sx={{ mt: 5, minHeight: '60vh' }} component="form" noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <Autocomplete
                                readOnly={readOnly}
                                getOptionLabel={(option) => option.personalData.lastName + ', ' + option.personalData.firstName}
                                options={employees}
                                value={leave.employeeInfoData}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onInputChange={onInputEmployeeChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{...params.InputProps, disableUnderline: readOnly}}
                                        required
                                        variant="standard"
                                        label="Employee Name" />
                                )}
                                onChange={(e, value) => {
                                    updateField("employeeInfoData", value);
                                }} />
                        </Grid>
                        <Grid item sm={6}></Grid>
                        <Grid item sm={2}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel required shrink={true}>Type</InputLabel>
                                <Select readOnly={readOnly}
                                    disableUnderline={readOnly}
                                    value={leave.type}
                                    onChange={(e) => updateField("type", e.target.value)}>
                                    <MenuItem value={''}>
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem key={'VACATION'} value={'VACATION'}>Vacation</MenuItem>
                                    <MenuItem key={'SICK'} value={'SICK'}>Sick</MenuItem>
                                    <MenuItem key={'EMERGENCY'} value={'EMERGENCY'}>Emergency</MenuItem>
                                    <MenuItem key={'MATERNITY'} value={'MATERNITY'}>Maternity</MenuItem>
                                    <MenuItem key={'PATERNITY'} value={'PATERNITY'}>Paternity</MenuItem>
                                    <MenuItem key={'SLW'} value={'SLW'}>Special Leave for Women</MenuItem>
                                    <MenuItem key={'VAW'} value={'VAW'}>Violence Against Women</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={10}></Grid>
                        <Grid item sm={2} sx={{display: "flex", justifyContent: "flex-start"}}>
                            <DatePicker label="From Date"
                                readOnly={readOnly}
                                slotProps={{
                                    textField: { variant: 'standard',
                                        required: true,
                                        InputLabelProps: {shrink: true},
                                        InputProps: {disableUnderline: readOnly},
                                        onChange: (newValue) => {
                                            updateField('fromDateMoment', newValue);
                                            updateField('fromDate', moment(newValue).format('MM/DD/YYYY'));
                                        }
                                    },
                                }}
                                onChange={(newValue) => {
                                    updateField('fromDateMoment', newValue);
                                    updateField('fromDate', moment(newValue).format('MM/DD/YYYY'));
                                }}
                                value={leave.fromDateMoment}
                                format="MM/DD/YYYY"
                                fullWidth />
                        </Grid>
                        <Grid item sm={2} sx={{display: "flex", justifyContent: "flex-start"}}>
                            <DatePicker label="To Date"
                                readOnly={readOnly}
                                slotProps={{
                                    textField: { variant: 'standard',
                                        required: true,
                                        InputLabelProps: {shrink: true},
                                        InputProps: {disableUnderline: readOnly},
                                        onChange: (newValue) => {
                                            updateField('toDateMoment', newValue);
                                            updateField('toDate', moment(newValue).format('MM/DD/YYYY'));
                                        }
                                    },
                                }}
                                onChange={(newValue) => {
                                    updateField('toDateMoment', newValue);
                                    updateField('toDate', moment(newValue).format('MM/DD/YYYY'));
                                }}
                                value={leave.toDateMoment}
                                format="MM/DD/YYYY"
                                fullWidth />
                        </Grid>
                        <Grid item sm={8}></Grid>
                        <Grid item sm={12}>
                            <TextField InputLabelProps={{ shrink: true }}
                                InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                label="Reason"
                                value={leave.reason}
                                onChange={(e) => updateField('reason', e.target.value)}
                                fullWidth
                                multiline
                                rows={4}
                                variant="standard" />
                        </Grid>
                    </Grid>
                </Box>
                <Dialog open={openMessage}>
                    <DialogTitle id="alert-dialog-title">
                        {success ? "Success": "Error"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenMessage(!openMessage)} autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AppBar position="fixed" color="inherit" sx={{ p: 1, top: 'auto', bottom: 0 }}>
                    <Box sx={{ pl: 8, display: 'flex', justifyContent: 'center', minHeight: 40 }}>
                        {!readOnly ?
                            <React.Fragment>
                                <Button onClick={save} variant="contained" startIcon={<SaveIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                    Save
                                </Button>
                                {!!id &&
                                    <Button onClick={cancel} variant="contained" startIcon={<CancelIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                        Cancel
                                    </Button>
                                }
                            </React.Fragment>
                        :
                            <Button onClick={() => setReadOnly(false)} variant="contained" startIcon={<EditIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                Edit
                            </Button>
                        }
                    </Box>
                </AppBar>
            </Paper>
        </LocalizationProvider>
    );
}