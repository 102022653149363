import React, { useLayoutEffect, useState } from "react";
import moment from 'moment';

import Box from '@mui/material/Box';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const emptyEducation = {
    level: '',
    schoolName: "",
    graduatedDate: "",
    graduatedDateMoment: null,
    course: ""
};
const educationLevels = [
    "PRIMARY",
    "SECONDARY",
    "VOCATIONAL",
    "TERTIARY"
];

export default function EducationalData({ data, updateEmployee, readOnly }) {
    const [educationsData, setEducationsData] = useState([]);

    useLayoutEffect(() => {
        setEducationsData(() => {
            let educations = [];
            data?.forEach((item) => {
                educations.push({
                    ...item,
                    graduatedDateMoment: item?.graduatedDate ? moment(item?.graduatedDate, 'MM/DD/YYYY'): null,
                });
            });

            return educations;
        });
    }, [data]);

    const updateEducation = (idx, fieldName, value) => {
        let educations = educationsData;
        educations[idx][fieldName] = (typeof value === 'string') ? value.toUpperCase(): value;
        setEducationsData([...educations]);
    };

    const addEducation = () => {
        setEducationsData(oldArray => [...oldArray, {
            ...emptyEducation,
        }]);
    };

    const removeLastEducation = () => {
        setEducationsData(oldArray => oldArray.slice(0, -1));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Typography variant="h6" align="left">
                        Education(s)
                    </Typography>
                </Grid>
                {educationsData.length > 0 ?
                    educationsData?.map((ed, idx) => {
                        return (
                            <React.Fragment key={`education-${idx}`}>
                                <Grid item sm={12}>
                                    <Typography variant="body1" align="left">
                                        Education { idx + 1 }
                                    </Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel required id={`ed-level-label-${idx}`} shrink={true}>Level</InputLabel>
                                        <Select labelId={`ed-level-label-${idx}`}
                                            id={`ed-level-${idx}`}
                                            readOnly={readOnly}
                                            disableUnderline={readOnly}
                                            value={ed.level}
                                            onChange={(e) => updateEducation(idx, "level", e.target.value)}
                                            onBlur={(e) => updateEmployee('educationsData', educationsData)}>
                                            <MenuItem value={null}>
                                                <em>None</em>
                                            </MenuItem>
                                            {educationLevels.map((el, idx) => {
                                                return (
                                                    <MenuItem key={el} value={el}>{el}</MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField required InputLabelProps={{ shrink: true }}
                                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                        id={`ed-schoolName-${idx}`}
                                        label="School Name"
                                        value={ed.schoolName}
                                        onChange={(e) => updateEducation(idx, "schoolName", e.target.value)}
                                        onBlur={(e) => updateEmployee('educationsData', educationsData)}
                                        fullWidth
                                        variant="standard" />
                                </Grid>
                                <Grid item sm={2}>
                                    <DatePicker label="Date Graduated"
                                        readOnly={readOnly}
                                        slotProps={{
                                            textField: { variant: 'standard',
                                                required: true,
                                                InputLabelProps: {shrink: true},
                                                InputProps: {disableUnderline: readOnly},
                                                onChange: (newValue) => {
                                                    updateEducation(idx, "graduatedDateMoment", newValue);
                                                    updateEducation(idx, "graduatedDate", moment(newValue).format('MM/DD/YYYY'));
                                                },
                                            },
                                        }}
                                        onChange={(newValue) => {
                                            updateEducation(idx, "graduatedDateMoment", newValue);
                                            updateEducation(idx, "graduatedDate", moment(newValue).format('MM/DD/YYYY'));
                                        }}
                                        maxDate={moment()}
                                        value={ed.graduatedDateMoment}
                                        format="MM/DD/YYYY"
                                        fullWidth />
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField InputLabelProps={{ shrink: true }}
                                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                        id={`ed-course-${idx}`}
                                        label="Course"
                                        value={ed.course}
                                        onChange={(e) => updateEducation(idx, "course", e.target.value)}
                                        onBlur={(e) => updateEmployee('educationsData', educationsData)}
                                        fullWidth
                                        variant="standard" />
                                </Grid>
                            </React.Fragment>
                        );
                    })
                    :
                    <Grid item sm={12}>
                        <Typography variant="subtitle1" sx={{ color:'gray', py:2, borderStyle:'dashed', borderWidth:1 }}>
                            No educational background added
                        </Typography>
                    </Grid>
                }
                {!readOnly &&
                    <Grid item sm={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Button onClick={addEducation} variant="contained" startIcon={<AddIcon />} sx={{ mt: 3, ml: 1 }}>
                                Add
                            </Button>
                            <Button onClick={removeLastEducation} variant="contained"
                                startIcon={<RemoveIcon />} sx={{ mt: 3, ml: 1 }}
                                disabled={educationsData?.length === 0}>
                                Remove
                            </Button>
                        </Box>
                    </Grid>
                }
            </Grid>
        </LocalizationProvider>
    );
}