import React from "react";

import Dashboard from "../../components/dashboard";
import EmployeeList from "./list";
import Employee from "./employee";

export default function Employees({isForm = false}) {
    return (
        <Dashboard title="Employee">
            {isForm ?
                <Employee />
                :
                <EmployeeList />
            }
        </Dashboard>
    );
}