import React, { useLayoutEffect, useState } from "react";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';

const emptyCharacterReference = {
    fullName: "",
    occupation: "",
    employer: "",
    contactNumber: ""
};

export default function CharacterReferenceData({ data, updateEmployee, readOnly }) {
    const [characterReferencesData, setCharacterReferencesData] = useState([]);

    useLayoutEffect(() => {
        setCharacterReferencesData((data) ? data : []);
    }, [data]);

    const updateCharacterReference = (idx, fieldName, value, type) => {
        let characterReferences = characterReferencesData;
        characterReferences[idx][fieldName] = ("number" === type) ? parseInt(value) : ((typeof value === 'string') ? value.toUpperCase(): value);
        setCharacterReferencesData([...characterReferences]);
    };

    const addCharacterReference = () => {
        setCharacterReferencesData(oldArray => [...oldArray, {
            ...emptyCharacterReference,
        }]);
    };

    const removeLastCharacterReference = () => {
        setCharacterReferencesData(oldArray => oldArray.slice(0, -1));
    };

    return (
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <Typography variant="h6" align="left">
                    Character Reference(s)
                </Typography>
            </Grid>
            {characterReferencesData?.length > 0 ?
                characterReferencesData?.map((cr, idx) => {
                    return (
                        <React.Fragment key={`characterReference-${idx}`}>
                            <Grid item sm={12}>
                                <Typography variant="body1" align="left">
                                    Character Reference { idx + 1 }
                                </Typography>
                            </Grid>
                            <Grid item sm={6} style={{ paddingTop: 0 }}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    id={`cr-fullName-${idx}`}
                                    label="Full Name"
                                    value={cr.fullName}
                                    onChange={(e) => updateCharacterReference(idx, "fullName", e.target.value)}
                                    onBlur={(e) => updateEmployee('characterReferencesData', characterReferencesData)}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                            <Grid item sm={3} style={{ paddingTop: 0 }}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    id={`cr-occupation-${idx}`}
                                    label="Occupation"
                                    value={cr.occupation}
                                    onChange={(e) => updateCharacterReference(idx, "occupation", e.target.value)}
                                    onBlur={(e) => updateEmployee('characterReferencesData', characterReferencesData)}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                            <Grid item sm={3} style={{ paddingTop: 0 }}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    id={`cr-employer-${idx}`}
                                    label="Employer"
                                    value={cr.employer}
                                    onChange={(e) => updateCharacterReference(idx, "employer", e.target.value)}
                                    onBlur={(e) => updateEmployee('characterReferencesData', characterReferencesData)}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                            <Grid item sm={3}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    id={`cr-contactNumber-${idx}`}
                                    label="Contact #"
                                    value={cr.contactNumber}
                                    onChange={(e) => updateCharacterReference(idx, "contactNumber", e.target.value)}
                                    onBlur={(e) => updateEmployee('characterReferencesData', characterReferencesData)}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                        </React.Fragment>
                    );
                })
                :
                <Grid item sm={12}>
                    <Typography variant="subtitle1" sx={{ color:'gray', py:2, borderStyle:'dashed', borderWidth:1 }}>
                        No character reference added
                    </Typography>
                </Grid>
            }
            {!readOnly &&
                <Grid item sm={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button onClick={addCharacterReference} variant="contained"
                            startIcon={<AddIcon />} sx={{ mt: 3, ml: 1 }}>
                            Add
                        </Button>
                        <Button onClick={removeLastCharacterReference} variant="contained"
                            startIcon={<RemoveIcon />} sx={{ mt: 3, ml: 1 }}
                            disabled={characterReferencesData?.length === 0}>
                            Remove
                        </Button>
                    </Box>
                </Grid>
            }
        </Grid>
    );
}