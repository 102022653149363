export const mergeJSON = (obj1, obj2) => {
    if (!obj1) return obj2;
    if (!obj2) return obj1;

    const merged = {}
    const keys1 = Object.keys(obj1);
    keys1.forEach(k1 => {
        merged[k1] = obj2[k1] || obj1[k1];
    });
    Object.keys(obj2).forEach(k2 => {
        if (!keys1.includes(k2)) merged[k2] = obj2[k2];
    });
    return merged;
};