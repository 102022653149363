import React, { useState } from 'react';
import { Navigate } from "react-router-dom";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import { login } from "../services/session";

export default function Login() {
    const defaultTheme = createTheme();
    const [showError, setShowError] = useState(false);

    const handleLogin = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const req = {
            username: data.get('username'),
            password: data.get('password'),
        };
        login(req).then(response => {
            setShowError(false);
            //get token from response
            const token = response.data.token;
            const user = {
                username: response.data.username,
                lastName: response.data.lastName,
                firstName: response.data.firstName,
                email: response.data.email,
                roles: response.data.roles,
            };

            //set JWT token to local
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));

            //set token to axios common header
            //setAuthToken(token);

            //redirect user to home page
            window.location.href = '/'
        }).catch(err => {
            setShowError(true);
        });
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                        {showError &&
                            <Typography color="red" component="h1" variant="subtitle2">
                                Invalid username or password
                            </Typography>
                        }
                        <TextField margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            variant="standard"
                            autoFocus />
                        <TextField margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            variant="standard"
                            autoComplete="current-password" />
                        <FormControlLabel control={<Checkbox value="remember" color="primary" />}
                            label="Remember me" />
                        <Button type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}>
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export function Logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    return (
        <Navigate to={{ pathname: '/login' }} />
    );
}
