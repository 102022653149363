import React, { useLayoutEffect, useState } from "react";
import moment from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';

const emptyEmployment = {
    fromYear: parseInt(moment().year()),
    toYear: parseInt(moment().year()),
    occupation: "",
    employer: ""
};

export default function EmploymentHistoryData({ data, updateEmployee, popUpMessage, readOnly }) {
    const [employmentsData, setEmploymentsData] = useState([]);

    useLayoutEffect(() => {
        setEmploymentsData((data) ? data : []);
    }, [data]);

    const updateEmployment = (idx, fieldName, value, type) => {
        let employments = employmentsData;
        employments[idx][fieldName] = ("number" === type) ? parseInt(value) : ((typeof value === 'string') ? value.toUpperCase(): value);
        setEmploymentsData([...employments]);
    };

    const addEmployment = () => {
        setEmploymentsData(oldArray => [...oldArray, {
            ...emptyEmployment,
        }]);
    };

    const removeLastEmployment = () => {
        setEmploymentsData(oldArray => oldArray.slice(0, -1));
    };

    return (
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <Typography variant="h6" align="left">
                    Employment(s)
                </Typography>
            </Grid>
            {employmentsData?.length > 0 ?
                employmentsData?.map((emp, idx) => {
                    return (
                        <React.Fragment key={`employment-${idx}`}>
                            <Grid item sm={12}>
                                <Typography variant="body1" align="left">
                                    Employment { idx + 1 }
                                </Typography>
                            </Grid>
                            <Grid item sm={1} style={{ paddingTop: 0 }}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    id={`emp-fromYear-${idx}`}
                                    label="From"
                                    value={emp.fromYear}
                                    type="number"
                                    inputProps={{ min: 0, max: parseInt(moment().year()), pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                    onChange={(e) => updateEmployment(idx, "fromYear", parseInt(e.target.value))}
                                    onBlur={(e) => updateEmployee('employmentsData', employmentsData)}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                            <Grid item sm={1} style={{ paddingTop: 0 }}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    id={`emp-toYear-${idx}`}
                                    label="To"
                                    value={emp.toYear}
                                    type="number"
                                    inputProps={{ min: 0, max: parseInt(moment().year()), pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                    onChange={(e) => updateEmployment(idx, "toYear", parseInt(e.target.value))}
                                    onBlur={(e) => updateEmployee('employmentsData', employmentsData)}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                            <Grid item sm={3} style={{ paddingTop: 0 }}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    id={`emp-occupation-${idx}`}
                                    label="Occupation"
                                    value={emp.occupation}
                                    onChange={(e) => updateEmployment(idx, "occupation", e.target.value)}
                                    onBlur={(e) => updateEmployee('employmentsData', employmentsData)}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                            <Grid item sm={3} style={{ paddingTop: 0 }}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    id={`emp-employer-${idx}`}
                                    label="Employer"
                                    value={emp.employer}
                                    onChange={(e) => updateEmployment(idx, "employer", e.target.value)}
                                    onBlur={(e) => updateEmployee('employmentsData', employmentsData)}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                        </React.Fragment>
                    );
                })
                :
                <Grid item sm={12}>
                    <Typography variant="subtitle1" sx={{ color:'gray', py:2, borderStyle:'dashed', borderWidth:1 }}>
                        No employment record added
                    </Typography>
                </Grid>
            }
            {!readOnly &&
                <Grid item sm={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button onClick={addEmployment} variant="contained"
                            startIcon={<AddIcon />} sx={{ mt: 3, ml: 1 }}>
                            Add
                        </Button>
                        <Button onClick={removeLastEmployment} variant="contained"
                            startIcon={<RemoveIcon />} sx={{ mt: 3, ml: 1 }}
                            disabled={employmentsData?.length === 0}>
                            Remove
                        </Button>
                    </Box>
                </Grid>
            }
        </Grid>
    );
}