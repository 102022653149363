import api from "./api";

export const getDivisions = ({name='', page = 0, size = 10, sort = 'name'}) => {
    const params = new URLSearchParams({
        name: name,
        page: page,
        size: size,
        sort: sort
    });
    return api.get("/api/division/list?" + params);
};

export const getAllDivisions = ({sort = 'name'}) => {
    const params = new URLSearchParams({
        sort: sort
    });
    return api.get("/api/division/list-all?" + params);
};

export const getDivision = ({id}) => {
    const params = new URLSearchParams({
        id: id
    });
    return api.get("/api/division?" + params);
}

export const saveDivision = ({division}) => {
    return api.post("/api/division/save", division);
};