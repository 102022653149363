import React from "react";

import Dashboard from "../../components/dashboard";
import DivisionList from "./list";
import Division from "./division";

export default function Divisions({isForm = false}) {
    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <Dashboard title="Divisions" hasUser={() => !(!user)}>
            {isForm ?
                <Division />
                :
                <DivisionList />
            }
        </Dashboard>
    );
}