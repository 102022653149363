import React from "react";

import Dashboard from "../../components/dashboard";
import PositionList from "./list";
import Position from "./position";

export default function Positions({isForm = false}) {
    return (
        <Dashboard title="Position">
            {isForm ?
                <Position />
                :
                <PositionList />
            }
        </Dashboard>
    );
}