import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const RouteGuard = ({ component: Component, ...rest }) => {

    function hasJWT() {
        let flag = false;

        //check user has JWT token
        localStorage.getItem("token") ? flag=true : flag=false;

        return flag;
    }

    return hasJWT() ? <Outlet /> : <Navigate to={{ pathname: '/login' }} />;
};

export default RouteGuard;