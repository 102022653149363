import api from "./api";

export const getBranches = ({name='', page = 0, size = 10, sort = 'code'}) => {
    const params = new URLSearchParams({
        name: name,
        page: page,
        size: size,
        sort: sort
    });
    return api.get("/api/branch/list?" + params);
};

export const getAllBranches = ({sort = 'code'}) => {
    const params = new URLSearchParams({
        sort: sort
    });
    return api.get("/api/branch/list-all?" + params);
};

export const getBranch = ({id}) => {
    const params = new URLSearchParams({
        id: id
    });
    return api.get("/api/branch?" + params);
}

export const saveBranch = ({branch}) => {
    return api.post("/api/branch/save", branch);
};