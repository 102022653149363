import api from "./api";

export const getPositions = ({name='', page = 0, size = 10, sort = 'name'}) => {
    const params = new URLSearchParams({
        name: name,
        page: page,
        size: size,
        sort: sort
    });
    if (sort?.includes('sortOrder')) {
        params.append('sort', 'name,asc');
    }
    return api.get("/api/position/list?" + params);
};

export const getAllPositions = ({sort = 'name'}) => {
    const params = new URLSearchParams({
        sort: sort
    });
    return api.get("/api/position/list-all?" + params);
};

export const getPosition = ({id}) => {
    const params = new URLSearchParams({
        id: id
    });
    return api.get("/api/position?" + params);
}

export const savePosition = ({position}) => {
    return api.post("/api/position/save", position);
};