import React, { useLayoutEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import {
    saveLeaves,
} from '../../services/employees';
import { mergeJSON } from '../../helpers/json-utils';

const emptyData = {
    id: '',
    vacationLeave: 0,
    sickLeave: 0,
    emergencyLeave: 0,
    maternityLeave: 0,
    paternityLeave: 0,
    parentalLeave: 0,
    specialWomanLeave: 0,
    violenceAgainstWomanLeave: 0
};

export default function LeaveDetails({data, hasLeaveInfo, gender, popUpMessage, setLoading, onChange}) {
    const [leaves, setLeaves] = useState(emptyData);
    const [readOnly, setReadOnly] = useState(false);

    useLayoutEffect(() => {
        if (!data) return;

        setLeaves(mergeJSON(emptyData, data));
    }, [data]);

    useLayoutEffect(() => {
        setReadOnly(hasLeaveInfo);
    }, [hasLeaveInfo]);

    const updateField = (fieldName, value, type) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string') ? value.toUpperCase(): value;
        if ("float" === type) {
            dirty[fieldName] = parseFloat(value).toFixed(2);
        }
        setLeaves(b => ({
            ...b,
            ...dirty
        }));
    };

    const save = async () => {
        setLoading(true);
        await saveLeaves({
            leaves: leaves
        }).then((res) => {
            setLoading(false);
            onChange(res.data);
            popUpMessage(true, 'Successfully saved leave details.');
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    };

    return (
        <React.Fragment>
            <Box component="form">
                <Grid container spacing={3}>
                    <Grid item sm={3}>
                        <TextField InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            label="Vacation Leave"
                            type="number"
                            value={leaves.vacationLeave}
                            inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                            onChange={(e) => updateField("vacationLeave", parseInt(e.target.value))}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    <Grid item sm={9}>
                    </Grid>
                    <Grid item sm={3}>
                        <TextField InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            label="Sick Leave"
                            type="number"
                            value={leaves.sickLeave}
                            inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                            onChange={(e) => updateField("sickLeave", parseInt(e.target.value))}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    <Grid item sm={9}>
                    </Grid>
                    <Grid item sm={3}>
                        <TextField InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            label="Emergency Leave"
                            type="number"
                            value={leaves.emergencyLeave}
                            inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                            onChange={(e) => updateField("emergencyLeave", parseInt(e.target.value))}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    {gender === 'FEMALE' &&
                        <React.Fragment>
                            <Grid item sm={9}>
                            </Grid>
                            <Grid item sm={3}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    label="Maternity Leave"
                                    type="number"
                                    value={leaves.maternityLeave}
                                    inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                    onChange={(e) => updateField("maternityLeave", parseInt(e.target.value))}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                        </React.Fragment>
                    }
                    {gender === 'MALE' &&
                        <React.Fragment>
                            <Grid item sm={9}>
                            </Grid>
                            <Grid item sm={3}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    label="Paternity Leave"
                                    type="number"
                                    value={leaves.paternityLeave}
                                    inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                    onChange={(e) => updateField("paternityLeave", parseInt(e.target.value))}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                        </React.Fragment>
                    }
                    <Grid item sm={9}>
                    </Grid>
                    <Grid item sm={3}>
                        <TextField InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            label="Parental Leave"
                            type="number"
                            value={leaves.parentalLeave}
                            inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                            onChange={(e) => updateField("parentalLeave", parseInt(e.target.value))}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    {gender === 'FEMALE' &&
                        <React.Fragment>
                            <Grid item sm={9}>
                            </Grid>
                            <Grid item sm={3}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    label="Special Leave for Women (SLW)"
                                    type="number"
                                    value={leaves.specialWomanLeave}
                                    inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                    onChange={(e) => updateField("specialWomanLeave", parseInt(e.target.value))}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                            <Grid item sm={9}>
                            </Grid>
                            <Grid item sm={3}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    label="Leave for victims of violence against women"
                                    type="number"
                                    value={leaves.violenceAgainstWomanLeave}
                                    inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                    onChange={(e) => updateField("violenceAgainstWomanLeave", parseInt(e.target.value))}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </Box>
            {!readOnly &&
                <AppBar position="fixed" color="inherit" sx={{ p: 1, top: 'auto', bottom: 0 }}>
                    <Box sx={{ pl: 8, display: 'flex', justifyContent: 'center', minHeight: 40 }}>
                        <Button onClick={save} variant="contained" startIcon={<SaveIcon />} sx={{ ml: 1, minWidth: 140 }}>
                            Save
                        </Button>
                    </Box>
                </AppBar>
            }
        </React.Fragment>
    );
}