import api from "./api";

export const ROLE_TYPES = [
    'ROLE_ADMIN',
    'ROLE_MODERATOR',
    'ROLE_USER'
];

export const getUsers = ({name='', page = 0, size = 10, sort = 'name'}) => {
    const params = new URLSearchParams({
        name: name,
        page: page,
        size: size,
        sort: sort
    });
    return api.get("/api/user/list?" + params);
};

export const getUser = ({id}) => {
    const params = new URLSearchParams({
        id: id
    });
    return api.get("/api/user?" + params);
};

export const signupUser = ({user}) => {
    return api.post("/api/user/signup", user);
};

export const updateUser = ({user}) => {
    return api.post("/api/user/save", user);
};