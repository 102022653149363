import React, { useLayoutEffect, useState } from "react";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';

const emptyBeneficiaryReference = {
    fullName: "",
    relation: "",
    contactNumber: ""
};

export default function BeneficiariesReferenceData({ data, updateEmployee, readOnly }) {
    const [beneficiariesData, setBeneficiariesData] = useState([]);

    useLayoutEffect(() => {
        setBeneficiariesData((data) ? data : []);
    }, [data]);

    const updateBeneficiaryReference = (idx, fieldName, value, type) => {
        let beneficiaries = beneficiariesData;
        beneficiaries[idx][fieldName] = ("number" === type) ? parseInt(value) : ((typeof value === 'string') ? value.toUpperCase(): value);
        setBeneficiariesData([...beneficiaries]);
    };

    const addBeneficiaryReference = () => {
        setBeneficiariesData(oldArray => [...oldArray, {
            ...emptyBeneficiaryReference,
        }]);
    };

    const removeLastBeneficiaryReference = () => {
        setBeneficiariesData(oldArray => oldArray.slice(0, -1));
    };

    return (
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <Typography variant="h6" align="left">
                    Beneficiary Reference(s)
                </Typography>
            </Grid>
            {beneficiariesData?.length > 0 ?
                beneficiariesData?.map((b, idx) => {
                    return (
                        <React.Fragment key={`beneficiary-${idx}`}>
                            <Grid item sm={12}>
                                <Typography variant="body1" align="left">
                                    Beneficiary Reference { idx + 1 }
                                </Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    id={`b-fullName-${idx}`}
                                    label="Full Name"
                                    value={b.fullName}
                                    onChange={(e) => updateBeneficiaryReference(idx, "fullName", e.target.value)}
                                    onBlur={(e) => updateEmployee('beneficiariesData', beneficiariesData)}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                            <Grid item sm={3}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    id={`b-relation-${idx}`}
                                    label="Relation"
                                    value={b.relation}
                                    onChange={(e) => updateBeneficiaryReference(idx, "relation", e.target.value)}
                                    onBlur={(e) => updateEmployee('beneficiariesData', beneficiariesData)}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                            <Grid item sm={3}>
                                <TextField InputLabelProps={{ shrink: true }}
                                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                    id={`b-contactNumber-${idx}`}
                                    label="Contact #"
                                    value={b.contactNumber}
                                    onChange={(e) => updateBeneficiaryReference(idx, "contactNumber", e.target.value)}
                                    onBlur={(e) => updateEmployee('beneficiariesData', beneficiariesData)}
                                    fullWidth
                                    variant="standard" />
                            </Grid>
                        </React.Fragment>
                    );
                })
                :
                <Grid item sm={12}>
                    <Typography variant="subtitle1" sx={{ color:'gray', py:2, borderStyle:'dashed', borderWidth:1 }}>
                        No beneficiary added
                    </Typography>
                </Grid>
            }
            {!readOnly &&
                <Grid item sm={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button onClick={addBeneficiaryReference} variant="contained"
                            startIcon={<AddIcon />} sx={{ mt: 3, ml: 1 }}>
                            Add
                        </Button>
                        <Button onClick={removeLastBeneficiaryReference} variant="contained"
                            startIcon={<RemoveIcon />} sx={{ mt: 3, ml: 1 }}
                            disabled={beneficiariesData?.length === 0}>
                            Remove
                        </Button>
                    </Box>
                </Grid>
            }
        </Grid>
    );
}