import axios from "axios";

export const login = ({username, password}) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/api/auth/signin`, {
        username: username,
        password: password,
    });
};

export const logout = () => {
     //setAuthToken(null);
     localStorage.removeItem("token");
     localStorage.removeItem("user");
};
