import api from "./api";

export const getRegions = ({name='', page = 0, size = 10, sort = 'name'}) => {
    const params = new URLSearchParams({
        name: name,
        page: page,
        size: size,
        sort: sort
    });
    return api.get("/api/region/list?" + params);
};

export const getAllRegions = ({sort = 'name'}) => {
    const params = new URLSearchParams({
        sort: sort
    });
    return api.get("/api/region/list-all?" + params);
};

export const getRegion = ({id}) => {
    const params = new URLSearchParams({
        id: id
    });
    return api.get("/api/region?" + params);
}

export const saveRegion = ({region}) => {
    return api.post("/api/region/save", region);
};