import React from "react";

import Dashboard from "../../components/dashboard";
import LeaveList from "./list";
import Leave from "./leave";

export default function Leaves({isForm = false}) {
    return (
        <Dashboard title="Leave">
            {isForm ?
                <Leave />
                :
                <LeaveList />
            }
        </Dashboard>
    );
}