import React from "react";

import Dashboard from "../../components/dashboard";
import DepartmentList from "./list";
import Department from "./department";

export default function Departments({isForm = false}) {
    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <Dashboard title="Department" hasUser={() => !(!user)}>
            {isForm ?
                <Department />
                :
                <DepartmentList />
            }
        </Dashboard>
    );
}
