import api from "./api";

export const getAreas = ({name='', page = 0, size = 10, sort = 'name'}) => {
    const params = new URLSearchParams({
        name: name,
        page: page,
        size: size,
        sort: sort
    });
    return api.get("/api/area/list?" + params);
};

export const getAllAreas = ({sort = 'name'}) => {
    const params = new URLSearchParams({
        sort: sort
    });
    return api.get("/api/area/list-all?" + params);
};

export const getArea = ({id}) => {
    const params = new URLSearchParams({
        id: id
    });
    return api.get("/api/area?" + params);
}

export const saveArea = ({area}) => {
    return api.post("/api/area/save", area);
};