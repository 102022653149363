import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import CakeIcon from '@mui/icons-material/Cake';
import Link2 from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';

import Title from '../../components/Title';
import { getEmployees } from "../../services/employees";
import {
    stringAvatar,
} from "../../helpers/string-utils";

export default function EmployeeList() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({});
    const [recordCount, setRecordCount] = useState(0);
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        getEmployees({
            name: searchParams.get('name') || '',
            page: searchParams.get('currentPage') || 0,
            sort: `${searchParams.get('sort') || 'lastName,firstName,suffix,middleName'},${searchParams.get('sortOrder') || 'asc'}`,
            size: searchParams.get('size') || 25
        }).then((res) => {
            setEmployees(res.data.content);
            setRecordCount(res.data.totalElements);
            setLoading(false);
        });
    }, [searchParams]);

    const sortField = (field) => {
        if (field !== searchParams.get('sort')) {
            updateSearch('sort', field);
            updateSearch('sortOrder', 'asc');
        } else {
            updateSearch('sortOrder', searchParams.get('sortOrder') === "asc" ? "desc" : "asc");
        }
    };

    const handleChangePage = (event, newPage) => {
        updateSearch('currentPage', newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        updateSearch('size', +event.target.value);
        updateSearch('currentPage', 0);
    };

    const updateSearch = (field, value) => {
        setSearchParams(d => {
            d.set(field, value);
            return d;
        })
    };

    return (
        <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Title>Employees</Title>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonGroup size="small" variant="contained" aria-label="outlined button group">
                            <Button startIcon={<AddCircleIcon />} component={Link} to={"/employees/employee"}>
                                Add Employee
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={6}>
                    <TextField label="Search Name"
                        InputProps={{
                            startAdornment: <SearchIcon />,
                            endAdornment: searchParams.get('name') && (
                                <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => updateSearch('name', '')} />
                            ),
                        }}
                        value={searchParams.get('name') || ''}
                        onChange={(e) => {
                            updateSearch('currentPage', 0);
                            updateSearch('name', e.target.value.toUpperCase());
                        }}
                        fullWidth
                        variant="standard" />
                </Grid>
            </Grid>
            <TableContainer>
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            <TableCell width={50}></TableCell>
                            <TableCell sortDirection={searchParams.get('sort') === 'lastName,firstName,suffix,middleName' ? searchParams.get('sortOrder') : false }>
                                <TableSortLabel active={searchParams.get('sort') === 'lastName,firstName,suffix,middleName'}
                                    direction={searchParams.get('sort') === 'lastName,firstName,suffix,middleName' ? searchParams.get('sortOrder') : 'asc'}
                                    onClick={() => sortField('lastName,firstName,suffix,middleName')}>
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell width={"25%"}>Division / Region / Area / Branch / Department</TableCell>
                            <TableCell width={"10%"}>
                                <TableSortLabel active={searchParams.get('sort') === 'employmentInfo.startDate'}
                                    direction={searchParams.get('sort') === 'employmentInfo.startDate' ? searchParams.get('sortOrder') : 'asc'}
                                    onClick={() => sortField('employmentInfo.startDate')}>
                                    Start Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell width={"10%"}>Contact #</TableCell>
                            <TableCell width={"15%"}>Email</TableCell>
                            <TableCell width={"10%"} sortDirection={searchParams.get('sort') === 'birthday.birthDate' ? searchParams.get('sortOrder') : false }>
                                <TableSortLabel active={searchParams.get('sort') === 'birthday.birthDate'}
                                    direction={searchParams.get('sort') === 'birthday.birthDate' ? searchParams.get('sortOrder') : 'asc'}
                                    onClick={() => sortField('birthday.birthDate')}>
                                    Birth Date
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ?
                            <TableRow>
                                <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                                    <CircularProgress color="inherit" />
                                </TableCell>
                            </TableRow>
                            :
                            employees.map((employee, idx) => {
                                return (
                                    <TableRow hover tabIndex={-1} key={employee.id}
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => navigate(`/employees/view/${employee.id}`)}>
                                        <TableCell width={50}>
                                            {employee.hasPhoto ?
                                                <Avatar src={`${process.env.REACT_APP_API_URL}/api/employee-photo/thumbnail/${employee.id}?rnd=${new Date().getTime()}`} />
                                                :
                                                <Avatar {...stringAvatar(`${employee.personalData.lastName},${[employee.personalData.firstName, employee.personalData.suffix].filter(a => !!a).join(' ')}`)} />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {employee.personalData.lastName}, {[employee.personalData.firstName, employee.personalData.suffix].filter(a => !!a).join(' ')} {employee.personalData.middleName?.charAt(0)}
                                            </Typography>
                                            {employee.employmentInfoData &&
                                                <Typography variant="caption">
                                                    {employee.employmentInfoData.positionData?.shortName || employee.employmentInfoData.positionData?.name} - {employee.employmentInfoData.type}
                                                </Typography>
                                            }
                                        </TableCell>
                                        <TableCell style={{
                                            width: "25%",
                                            minWidth: 350,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            borderStyle: "border-box" }}>
                                            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                                                {employee.employmentInfoData?.branchDataList?.map((branch, idx) => {
                                                    return (
                                                        <Chip key={`branch-${idx}`} label={`${branch.code} - ${branch.name}`} />
                                                    );
                                                })}
                                                {employee.employmentInfoData?.departmentDataList?.map((department, idx) => {
                                                    return (
                                                        <Chip key={`department-${idx}`} label={`${department.code} - ${department.name}`} />
                                                    );
                                                })}
                                                {employee.employmentInfoData?.managedBranchDataList?.map((branch, idx) => {
                                                    return (
                                                        <Chip key={`managedBranch-${idx}`} label={`${branch.code} - ${branch.name}`} />
                                                    );
                                                })}
                                                {employee.employmentInfoData?.managedAreaDataList?.map((area, idx) => {
                                                    return (
                                                        <Chip key={`managedArea-${idx}`} label={`${area.name}`} />
                                                    );
                                                })}
                                                {employee.employmentInfoData?.managedRegionDataList?.map((region, idx) => {
                                                    return (
                                                        <Chip key={`managedRegion-${idx}`} label={`${region.name}`} />
                                                    );
                                                })}
                                                {employee.employmentInfoData?.managedDivisionDataList?.map((division, idx) => {
                                                    return (
                                                        <Chip key={`managedDivision-${idx}`} label={`${division.name}`} />
                                                    );
                                                })}
                                                {employee.employmentInfoData?.managedDepartmentDataList?.map((department, idx) => {
                                                    return (
                                                        <Chip key={`managedDepartment-${idx}`} label={`${department.code} - ${department.name}`} />
                                                    );
                                                })}
                                            </Stack>
                                        </TableCell>
                                        <TableCell style={{
                                            width: "10%",
                                            minWidth: 150,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            borderStyle: "border-box" }}>
                                            {employee.employmentInfoData?.startDate}
                                        </TableCell>
                                        <TableCell style={{
                                            width: "10%",
                                            minWidth: 150,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            borderStyle: "border-box" }}>
                                            {!(!employee.personalData.contactNumber) &&
                                                <Stack direction="row" alignItems="center" gap={1}>
                                                    <PhoneIcon />
                                                    <Link2 href={`tel:${employee.personalData.contactNumber}`}
                                                        underline="hover"
                                                        onClick={(e) => e.stopPropagation()}>
                                                        {employee.personalData.contactNumber}
                                                    </Link2>
                                                </Stack>
                                            }
                                        </TableCell>
                                        <TableCell style={{
                                            width: "15%",
                                            minWidth: 250,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            borderStyle: "border-box" }}>
                                            {!(!employee.personalData.email) &&
                                                <Stack direction="row" alignItems="center" gap={1}>
                                                    <EmailIcon />
                                                    <Link2 href={`mailto:${employee.personalData.email}`}
                                                        underline="hover"
                                                        onClick={(e) => e.stopPropagation()}>{employee.personalData.email}</Link2>
                                                </Stack>
                                            }
                                        </TableCell>
                                        <TableCell style={{
                                            width: "10%",
                                            minWidth: 150,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            borderStyle: "border-box" }}>
                                            {!(!employee.personalData.birthDate) &&
                                                <Stack direction="row" alignItems="center" gap={1}>
                                                    <CakeIcon />
                                                    <Typography variant="body2">{employee.personalData.birthDate}</Typography>
                                                </Stack>
                                            }
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination component="div"
                count={recordCount}
                rowsPerPage={parseInt(searchParams.get('size') || 25)}
                page={recordCount > 0 ? parseInt(searchParams.get('currentPage') || 0) : 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage} />
        </Paper>
    );
}