import api from "./api";

export const getLeaves = ({name='', page = 0, size = 10, sort = 'name'}) => {
    const params = new URLSearchParams({
        name: name,
        page: page,
        size: size,
        sort: sort
    });
    return api.get("/api/employee-leave/leave/list?" + params);
};

export const getLeave = ({id}) => {
    const params = new URLSearchParams({
        id: id
    });
    return api.get("/api/employee-leave/leave?" + params);
}

export const saveLeave = ({leave}) => {
    return api.post("/api/employee-leave/leave/add", leave);
};