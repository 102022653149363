import api from "./api";

export const getDepartments = ({name='', page = 0, size = 10, sort = 'code'}) => {
    const params = new URLSearchParams({
        name: name,
        page: page,
        size: size,
        sort: sort
    });
    return api.get("/api/department/list?" + params);
};

export const getAllDepartments = ({sort = 'code'}) => {
    const params = new URLSearchParams({
        sort: sort
    });
    return api.get("/api/department/list-all?" + params);
};

export const getDepartment = ({id}) => {
    const params = new URLSearchParams({
        id: id
    });
    return api.get("/api/department?" + params);
}

export const saveDepartment = ({department}) => {
    return api.post("/api/department/save", department);
};