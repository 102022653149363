import React from "react";

import Dashboard from "../../components/dashboard";
import AreaList from "./list";
import Area from "./area";

export default function Areas({isForm = false}) {
    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <Dashboard title="Areas" hasUser={() => !(!user)}>
            {isForm ?
                <Area />
                :
                <AreaList />
            }
        </Dashboard>
    );
}