import React, { useLayoutEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import {
    saveAttachment,
    removeAttachment,
} from '../../services/attachments';

const emptyAttachment = {
    id: '',
    description: '',
};

export default function EmployeeAttachment({data, empId, popUpMessage, setLoading, onChange}) {
    const [attachmentsData, setAttachmentsData] = useState([]);
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
    const [currentAttachment, setCurrentAttachment] = useState(emptyAttachment);

    useLayoutEffect(() => {
        setAttachmentsData((data) ? data : []);
    }, [data]);

    const updateAttachment = (idx, fieldName, value, type) => {
        let attachments = attachmentsData;
        attachments[idx][fieldName] = ("number" === type) ? parseInt(value) : ((typeof value === 'string') ? value.toUpperCase(): value);
        attachments[idx]['dirty'] = true;
        setAttachmentsData([...attachments]);
    };

    const addAttachment = () => {
        setAttachmentsData(oldArray => [...oldArray, {
            ...emptyAttachment,
        }]);
    };

    const removeConfirmed = () => {
        if (!currentAttachment?.id) {
            return;
        }

        setOpenRemoveDialog(false);
        setLoading(true);
        const id = currentAttachment.id;
        removeAttachment({ id }).then((res) => {
            setAttachmentsData(oldArray => oldArray.filter(item => item.id !== id));
            setCurrentAttachment(null);
            setLoading(false);
            popUpMessage(true, 'Successfully removed employee attachment.');
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    };

    const handleUploadClick = (idx, event) => {
        const file = event.target.files[0];
        const filesize = ((file.size / 1024) / 1024).toFixed(4);
        if (filesize > 10) {
            popUpMessage(false, "File must be less than 10MB");
            event.target.value = "";
            return;
        }

        let data = new FormData();
        data.append('file', file, file.name);
        data.append('empId', empId);
        data.append('description', attachmentsData[idx].description);

        setLoading(true);
        saveAttachment(data).then((res) => {
            let newAttachments = attachmentsData;
            newAttachments[idx] = res.data;
            setAttachmentsData([...newAttachments]);
            setLoading(false);
            popUpMessage(true, 'Successfully uploaded employee attachment.');
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    };

    return (
        <Box component="form">
            <Grid container spacing={3}>
                {attachmentsData?.length > 0 ?
                    attachmentsData?.map((attachment, idx) => {
                        return (
                            <React.Fragment key={`attachment-${idx}`}>
                                <Grid item sm={10}>
                                    <TextField InputLabelProps={{ shrink: true }}
                                        InputProps={{readOnly: !!attachment.id, disableUnderline: !!attachment.id}}
                                        id={`attachment-description-${idx}`}
                                        label="Description"
                                        value={attachment.description}
                                        onChange={(e) => updateAttachment(idx, "description", e.target.value)}
                                        fullWidth
                                        variant="standard" />
                                </Grid>
                                <Grid item sm={1}>
                                    {attachment?.description && !attachment.id &&
                                        <IconButton variant="contained" component="label">
                                            <FileUploadIcon fontSize="inherit" />
                                            <input type="file" hidden onChange={(e) => handleUploadClick(idx, e)} />
                                        </IconButton>
                                    }
                                    {attachment.id &&
                                        <IconButton variant="contained" component="label">
                                            <a href={`${process.env.REACT_APP_API_URL}/api/employee-attachment/file/${attachment.id}`} target="_blank" rel="noreferrer" download>
                                                <FileDownloadIcon fontSize="inherit" />
                                            </a>
                                        </IconButton>
                                    }
                                </Grid>
                                <Grid item sm={1}>
                                    {attachment?.id &&
                                        <IconButton id={`attachment-delete-${idx}`} aria-label="delete" size="large" onClick={() => {
                                                setCurrentAttachment(attachment);
                                                setOpenRemoveDialog(true);
                                            }}>
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                </Grid>
                            </React.Fragment>
                        );
                    })
                    :
                    <Grid item sm={12}>
                        <Typography variant="subtitle1" sx={{ color:'gray', py:2, borderStyle:'dashed', borderWidth:1 }}>
                            No attachment added
                        </Typography>
                    </Grid>
                }
                <Grid item sm={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button onClick={addAttachment} variant="contained"
                            startIcon={<AddIcon />} sx={{ mt: 3, ml: 1 }}>
                            Add
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={openRemoveDialog}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove this attachment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setOpenRemoveDialog(!openRemoveDialog)}>Cancel</Button>
                    <Button onClick={removeConfirmed}>Ok</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}