import React from "react";

import Dashboard from "../../components/dashboard";
import UserList from "./list";
import User from "./user";

export default function Users({isForm = false}) {
    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <Dashboard title="Users" hasUser={() => !(!user)}>
            {isForm ?
                <User />
                :
                <UserList />
            }
        </Dashboard>
    );
}