import React, { useLayoutEffect, useState } from "react";
import moment from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const emptyTrainingAttended = {
    activity: "",
    attendedDate: "",
    attendedDateMoment: null,
    placeHeld: "",
};

export default function TrainingAttendedData({ data, updateEmployee, readOnly }) {
    const [trainingsData, setTrainingsData] = useState([]);

    useLayoutEffect(() => {
        setTrainingsData(() => {
            let trainingsAttended = [];
            data?.forEach((item) => {
                trainingsAttended.push({
                    ...item,
                    attendedDateMoment: item?.attendedDate ? moment(item?.attendedDate, 'MM/DD/YYYY'): null,
                });
            });

            return trainingsAttended;
        });
    }, [data]);

    const updateTrainingAttended = (idx, fieldName, value, type) => {
        let trainingsAttended = trainingsData;
        trainingsAttended[idx][fieldName] = ("number" === type) ? parseInt(value) : ((typeof value === 'string') ? value.toUpperCase(): value);
        setTrainingsData([...trainingsAttended]);
    };

    const addTrainingAttended = () => {
        setTrainingsData(oldArray => [...oldArray, {
            ...emptyTrainingAttended,
        }]);
    };

    const removeLastTrainingAttended = () => {
        setTrainingsData(oldArray => oldArray.slice(0, -1));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Typography variant="h6" align="left">
                        Training(s) Attended
                    </Typography>
                </Grid>
                {trainingsData?.length > 0 ?
                    trainingsData?.map((ta, idx) => {
                        return (
                            <React.Fragment key={`training-${idx}`}>
                                <Grid item sm={12}>
                                    <Typography variant="body1" align="left">
                                        Training(s) Attended
                                    </Typography>
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField InputLabelProps={{ shrink: true }}
                                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                        id={`ta-activity-${idx}`}
                                        label="Activity"
                                        value={ta.activity}
                                        onChange={(e) => updateTrainingAttended(idx, "activity", e.target.value)}
                                        onBlur={(e) => updateEmployee('trainingsData', trainingsData)}
                                        fullWidth
                                        variant="standard" />
                                </Grid>
                                <Grid item sm={2}>
                                    <DatePicker label="Date Attended"
                                        readOnly={readOnly}
                                        slotProps={{
                                            textField: { variant: 'standard',
                                                required: true,
                                                InputLabelProps: {shrink: true},
                                                InputProps: {disableUnderline: readOnly},
                                                onChange: (newValue) => {
                                                    updateTrainingAttended(idx, "attendedDateMoment", newValue);
                                                    updateTrainingAttended(idx, "attendedDate", moment(newValue).format('MM/DD/YYYY'));
                                                },
                                            },
                                        }}
                                        onChange={(newValue) => {
                                            updateTrainingAttended(idx, "attendedDateMoment", newValue);
                                            updateTrainingAttended(idx, "attendedDate", moment(newValue).format('MM/DD/YYYY'));
                                        }}
                                        maxDate={moment()}
                                        value={ta.attendedDateMoment}
                                        format="MM/DD/YYYY"
                                        fullWidth />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField InputLabelProps={{ shrink: true }}
                                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                        id={`ta-placeHeld-${idx}`}
                                        label="Place Held"
                                        value={ta.placeHeld}
                                        onChange={(e) => updateTrainingAttended(idx, "placeHeld", e.target.value)}
                                        onBlur={(e) => updateEmployee('trainingsData', trainingsData)}
                                        fullWidth
                                        variant="standard" />
                                </Grid>
                            </React.Fragment>
                        );
                    })
                    :
                    <Grid item sm={12}>
                        <Typography variant="subtitle1" sx={{ color:'gray', py:2, borderStyle:'dashed', borderWidth:1 }}>
                            No training attended added
                        </Typography>
                    </Grid>
                }
                {!readOnly &&
                    <Grid item sm={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Button onClick={addTrainingAttended} variant="contained"
                                startIcon={<AddIcon />} sx={{ mt: 3, ml: 1 }}>
                                Add
                            </Button>
                            <Button onClick={removeLastTrainingAttended} variant="contained"
                                startIcon={<RemoveIcon />} sx={{ mt: 3, ml: 1 }}
                                disabled={trainingsData?.length === 0}>
                                Remove
                            </Button>
                        </Box>
                    </Grid>
                }
            </Grid>
        </LocalizationProvider>
    );
}