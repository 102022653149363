import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import Avatar from '@mui/material/Avatar';

import Title from '../../components/Title';
import { getLeaves } from "../../services/leaves";
import {
    stringAvatar,
} from "../../helpers/string-utils";

export default function LeaveList() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({});
    const [recordCount, setRecordCount] = useState(0);
    const [leaves, setLeaves] = useState([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        getLeaves({
            name: searchParams.get('name') || '',
            page: searchParams.get('currentPage') || 0,
            sort: `${searchParams.get('sort') || 'fromDate'},${searchParams.get('sortOrder') || 'asc'}`,
            size: searchParams.get('size') || 25
        }).then((res) => {
            setLeaves(res.data.content);
            setRecordCount(res.data.totalElements);
            setLoading(false);
        });
    }, [searchParams]);

    const sortField = (field) => {
        if (field !== searchParams.get('sort')) {
            updateSearch('sort', field);
            updateSearch('sortOrder', 'asc');
        } else {
            updateSearch('sortOrder', searchParams.get('sortOrder') === "asc" ? "desc" : "asc");
        }
    };

    const handleChangePage = (event, newPage) => {
        updateSearch('currentPage', newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        updateSearch('size', +event.target.value);
        updateSearch('currentPage', 0);
    };

    const updateSearch = (field, value) => {
        setSearchParams(d => {
            d.set(field, value);
            return d;
        })
    };

    return (
        <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Title>Leave List</Title>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonGroup size="small" variant="contained" aria-label="outlined button group">
                            <Button startIcon={<AddCircleIcon />} component={Link} to={"/leaves/leave"}>
                                Add Leave
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={6}>
                    <TextField label="Search Name"
                        InputProps={{
                            startAdornment: <SearchIcon />,
                            endAdornment: searchParams.get('name') && (
                                <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => updateSearch('name', '')} />
                            )
                        }}
                        value={searchParams.get('name') || ''}
                        onChange={(e) => {
                            updateSearch('currentPage', 0);
                            updateSearch('name', e.target.value.toUpperCase());
                        }}
                        fullWidth
                        variant="standard" />
                </Grid>
            </Grid>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell width={50}></TableCell>
                            <TableCell sortDirection={searchParams.get('sort') === 'leaveInfo.employee.lastName,leaveInfo.employee.firstName,leaveInfo.employee.suffix,leaveInfo.employee.middleName' ? searchParams.get('sortOrder') : false }>
                                <TableSortLabel active={searchParams.get('sort') === 'leaveInfo.employee.lastName,leaveInfo.employee.firstName,leaveInfo.employee.suffix,leaveInfo.employee.middleName'}
                                    direction={searchParams.get('sort') === 'lastName,firstName,suffix,middleName' ? searchParams.get('sortOrder') : 'asc'}
                                    onClick={() => sortField('leaveInfo.employee.lastName,leaveInfo.employee.firstName,leaveInfo.employee.suffix,leaveInfo.employee.middleName')}>
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell width={"20%"}>Type</TableCell>
                            <TableCell width={"20%"} sortDirection={searchParams.get('sort') === 'fromDate' ? searchParams.get('sortOrder') : false }>
                                <TableSortLabel active={searchParams.get('sort') === 'fromDate'}
                                    direction={searchParams.get('sort') === 'fromDate' ? searchParams.get('sortOrder') : 'asc'}
                                    onClick={() => sortField('fromDate')}>
                                    From
                                </TableSortLabel>
                            </TableCell>
                            <TableCell width={"20%"} sortDirection={searchParams.get('sort') === 'toDate' ? searchParams.get('sortOrder') : false }>
                                <TableSortLabel active={searchParams.get('sort') === 'toDate'}
                                    direction={searchParams.get('sort') === 'toDate' ? searchParams.get('sortOrder') : 'asc'}
                                    onClick={() => sortField('toDate')}>
                                    To
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ?
                            <TableRow>
                                <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                                    <CircularProgress color="inherit" />
                                </TableCell>
                            </TableRow>
                            :
                            leaves.map((leave, idx) => {
                                return (
                                    <TableRow hover tabIndex={-1} key={leave.id}
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => navigate(`/leaves/view/${leave.id}`)}>
                                        <TableCell width={50}>
                                            {leave.employeeInfoData.hasPhoto ?
                                                <Avatar src={`${process.env.REACT_APP_API_URL}/api/employee-photo/thumbnail/${leave.employeeInfoData.id}?rnd=${new Date().getTime()}`} />
                                                :
                                                <Avatar {...stringAvatar(`${leave.employeeInfoData.personalData.lastName},${[leave.employeeInfoData.personalData.firstName, leave.employeeInfoData.personalData.suffix].filter(a => !!a).join(' ')}`)} />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {leave.employeeInfoData.personalData.lastName}, {[leave.employeeInfoData.personalData.firstName, leave.employeeInfoData.personalData.suffix].filter(a => !!a).join(' ')} {leave.employeeInfoData.personalData.middleName?.charAt(0)}
                                            </Typography>
                                            {leave.employeeInfoData.employmentInfoData &&
                                                <Typography variant="caption">
                                                    {leave.employeeInfoData.employmentInfoData.positionData?.shortName || leave.employeeInfoData.employmentInfoData.positionData?.name} - {leave.employeeInfoData.employmentInfoData.type}
                                                </Typography>
                                            }
                                        </TableCell>
                                        <TableCell style={{
                                            width: "20%",
                                            minWidth: 100,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            borderStyle: "border-box" }}>
                                            <Typography>{leave.type}</Typography>
                                        </TableCell>
                                        <TableCell style={{
                                            width: "20%",
                                            minWidth: 100,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            borderStyle: "border-box" }}>
                                            <Typography>{leave.fromDate}</Typography>
                                        </TableCell>
                                        <TableCell style={{
                                            width: "20%",
                                            minWidth: 100,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            borderStyle: "border-box" }}>
                                            <Typography>{leave.toDate}</Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination component="div"
                count={recordCount}
                rowsPerPage={parseInt(searchParams.get('size') || 25)}
                page={recordCount > 0 ? parseInt(searchParams.get('currentPage') || 0) : 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage} />
        </Paper>
    );
}