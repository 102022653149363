import axios from "axios";

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL, //your api URL
    crossDomain: true,
    headers: {
        "Content-type": "application/json",
    }
});

api.interceptors.request.use(
    config => {
        config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (401 === error.response.status) {
            window.location = '/logout';
        } else {
            return Promise.reject(error);
        }
    }
);

export default api;
