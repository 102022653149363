import React from "react";

import Dashboard from "../../components/dashboard";
import RegionList from "./list";
import Region from "./region";

export default function Regions({isForm = false}) {
    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <Dashboard title="Regions" hasUser={() => !(!user)}>
            {isForm ?
                <Region />
                :
                <RegionList />
            }
        </Dashboard>
    );
}